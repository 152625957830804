import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../Utils/app";
import toast from "react-hot-toast";
import axios from "axios";
import { db } from "../Utils/firebase";
import { useAuth } from "../AuthContext";
import TagManager from "react-gtm-module";
import { useLocation } from "react-router-dom";
import { eu_countries } from "../Utils/ArrayData";
import { publicEmailServices } from "../Utils/ArrayData";
const { v4: uuidv4 } = require('uuid');


const isValidEmail = async (email) => {
  // Basic email format validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    return false;
  }

  // Extract the domain part of the email
  // const emailDomain = email.split('@')[1];

  // Check if the domain is in the allowed domains list
  // return publicEmailServices.includes(emailDomain);
  let emailValidity = false;

  axios.defaults.withCredentials = false;
  await axios
    .get(
      "https://emailvalidation.abstractapi.com/v1/?api_key=fb718f22f2eb4a89b7a2e8997ab92050&email=" +
        email,
    )
    .then((response) => {
      if (
        response.data?.deliverability == "DELIVERABLE" &&
        response.data?.is_valid_format?.value &&
        !response.data?.is_disposable_email?.value
      ) {
        emailValidity = true;
      } else {
        emailValidity = false;
      }
    })
    .catch((error) => {
      emailValidity = false;
    });

  return emailValidity;
};


const CreateAccount3 = ({ setCancelModal, geoData, campaignData }) => {
  const { state, dispatch } = useAuth();
  const location = useLocation();
  const effectRan = useRef(false);
  const navigate = useNavigate();
  const [ipCurrency, setIpCurrency] = useState();
  const [formControls, setFormControls] = useState({
    email: "",
    password: "",
    agree: true,
  });
  const [loading, setLoading] = useState(false);
  let loadingToast;
  const plans = ["P1", "P2", "P3"];


  useEffect(() => {
    const scrollToTop = () => {
      if (window.innerWidth <= 768) {
        // Check if the screen width is 768px or less (commonly considered mobile)
        window.scrollTo({
          top: 100,
          behavior: "smooth", // Smooth scroll
        });
      }
    };
    scrollToTop();
  }, []);

  useEffect(() => {
    let currency = "USD";

    if (geoData?.country) {
      if (eu_countries.includes(geoData.country)) {
        currency = "EUR";
      } else if (geoData.country == "United Kingdom") {
        currency = "GBP";
      } else {
        currency = "USD";
      }
    }

    setIpCurrency(currency);
  }, [geoData]);

  useEffect(() => {

    // if (geoData == null) return;
    if (effectRan.current) return; // Return early if effect has already run once

    const loadingToast = toast.loading("Loading");

    const trackPage = async () => {
      axios.defaults.withCredentials = true;
      return await axios.post(`${baseUrl}/api/lp1-register-page-visit`, {

        ip_address: geoData?.traits?.ip_address ? geoData?.traits?.ip_address : '34834',
        campaign_name: campaignData.camp,
      });
    };

    trackPage()
      .then((resp) => {
        toast.dismiss(loadingToast);
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss(loadingToast);
        toast.error(error?.message);
      });


    if(campaignData?.email){
      let userData = {
        email: campaignData?.email,
        campaign_name: campaignData.camp,
      }

      createUser(userData);
    }

    effectRan.current = true; // Mark effect as run
  }, [geoData,campaignData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formControls?.agree) {
      toast.error("You have to agree to Terms and Conditions.");
      return;
    }
    let checkValidity = await isValidEmail(formControls.email);
    if (!checkValidity) {
      toast.error("Your email address is invalid.");
      return;
    }

    const uniqueId = uuidv4();
    let postData = {
      ...formControls,
      pub_id: uniqueId,
      campaign_name: campaignData.camp ? campaignData.camp: "gad",
    };
    setLoading(true);

    await axios
      .post(`${baseUrl}/api/promotion-register`, postData)
      .then((response) => {
        console.log(response);
        setLoading(false);
        toast.dismiss(loadingToast);
        toast.success("Account Created Successfully");
        TagManager.dataLayer({
          dataLayer: {
            event: "registration_submission",
            page: location.pathname + location.search,
          },
        });
        
        setFormControls({
          email: "",
          password: "",
        });

        if(response?.data?.data?.is_primeplay){
          const pubId = campaignData.camp ? campaignData.camp: "gad";
          const email = formControls.email;
          const password = formControls.password;

          const url = `https://dl.tdnlinks.com/100002833?subaffiliate_id=${pubId}&ext_email_passing=${encodeURIComponent(email)}&ext_password=${encodeURIComponent(password)}`;

          window.location.href = url;
        }else{
            navigate(`/subscribe39?plan=${plans[response?.data?.data?.toPay - 1]}`, {
              state: {
                dbUser: response?.data?.data,
                currency: ipCurrency,
                geoData: geoData,
                has_trial: response?.data?.data?.isTrial,
                campaignData: campaignData,
              },
            });
        }

        
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss(loadingToast);
        toast.error(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
        );
        setLoading(false);
      });
  };

  const handleLogin = () => {
    navigate("/lp3");
  };


  const createUser = async (userData) => {
    const loadingToast = toast.loading("Loading");
    let postData = {
      ...userData
    };
    setLoading(true);

    await axios
      .post(`${baseUrl}/api/register`, postData)
      .then((response) => {
        console.log(response);
        setLoading(false);
        toast.dismiss(loadingToast);
        toast.success("Account Created Successfully");
        TagManager.dataLayer({
          dataLayer: {
            event: "registration_submission",
            page: location.pathname + location.search,
          },
        });

        navigate(`/subscribe?plan=${plans[response?.data?.data?.toPay - 1]}`, {
          state: {
            dbUser: response?.data?.data,
            currency: ipCurrency,
            geoData: geoData,
            has_trial: response?.data?.data?.isTrial,
            campaignData: campaignData ,
          },
        });
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss(loadingToast);
        toast.error(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
        );
        setLoading(false);
      });
  }

  return (
    <div className=" bg-[#BFBEBE] py-5 flex items-center justify-center absolute w-full h-full top-0 left-0 bg-opacity-60">
      <div className="w-[95%] lg:w-[800px] bg-white rounded-xl flex flex-wrap">
        <div
          className=" w-full lg:w-[300px] bg-cover lg:pt-[50px] px-[30px]  flex flex-col justify-between rounded-t-xl"
          style={{ backgroundImage: "url(/assets/Images/CreateAccImg1.png)" }}
        >
          <div>
            <img
              width={90}
              className="hidden lg:block mx-auto"
              src="/assets/Images/logo-white.png"
            />

            <p className="font-bold text-white mt-[0px] lg:mt-[0px] mb-[10px] lg:mb-[30px] text-lg">
              Get full access to OneHubPlay in 3 easy steps:
            </p>

            <ul className="text-base mb-[20px] lg:mb-0">
              <li className="flex gap-x-2 items-center text-white mb-2">
                <div className="w-[20px] h-[20px] bg-[#7FB07F] rounded-full flex items-center justify-center">
                  <p className="text-white">1.</p>
                </div>
                <span>Create your account</span>
              </li>
              <li className="flex gap-x-2 items-center text-white mb-2">
                <div className="w-[20px] h-[20px] bg-gray-300 rounded-full flex items-center justify-center">
                  <p className="text-white">2.</p>
                </div>
                <span>Verify your account</span>
              </li>
              <li className="flex gap-x-2 items-center text-white mb-2">
                <div className="w-[20px] h-[20px] bg-gray-300 rounded-full flex items-center justify-center">
                  <p className="text-white">3.</p>
                </div>
                <span>Access exclusive content</span>
              </li>
            </ul>
          </div>

          <div className=" pb-[50px] flex justify-center ">
            <div className="px-6 border-r border-gray-600">
              <img
                className="h-[50px] hidden lg:block "
                src="/assets/Images/games-icon.png"
              />
            </div>
            <div className="px-6">
              <img
                className=" h-[60px] hidden lg:block "
                src="/assets/Images/music.png"
              />
            </div>



          </div>
        </div>
        <form
          onSubmit={(e) => handleSubmit(e)}
          className="pt-[20px] lg:pt-[100px] pb-[20px] px-[20px] lg:px-[50px] flex-shrink-0 flex-1"
        >
          <h1 className="mb-[10px] md:mb-[40px] text-gray-500 text-2xl md:text-3xl text-center font-semibold">
            Create your account
          </h1>
          <div className="mb-[15px] md:mb-[30px] border border-gray-300 flex items-center p-3 gap-x-2 rounded-md">
            <img
              className="w-[20px] block flex-shrink-0 bg-transparent "
              width="30"
              src="/assets/Images/CreateAccImg3.png"
            />
            <input
              type="email"
              required
              value={formControls.email}
              onChange={(e) =>
                setFormControls({ ...formControls, email: e.target.value })
              }
              className=" block w-full focus:outline-none"
              placeholder="Fill in your email"
            />
          </div>
          <div className=" mb-[15px] md:mb-[30px] border border-gray-300 flex items-center p-3 gap-x-2 rounded-md">
            <img
              className="w-[20px] block flex-shrink-0 bg-transparent "
              width="30"
              src="/assets/Images/CreateAccImg4.png"
            />
            <input
              type="password"
              required
              value={formControls.password}
              onChange={(e) =>
                setFormControls({ ...formControls, password: e.target.value })
              }
              className=" block w-full focus:outline-none"
              placeholder="Create your password"
            />
          </div>
          <p className="text-sm text-gray-500 mb-3">
            Already have an account?{" "}
            <Link
              to={"/register"}
              state={{ isLogin: true }}
              className="underline"
            >
              Click here
            </Link>{" "}
            to login.
          </p>
          <label className=" text-xs md:text-sm text-gray-500 flex gap-x-2 items-start mb-8">
            <div className="flex-shrink-0 ">
              <input
                onChange={() =>
                  setFormControls({
                    ...formControls,
                    agree: !formControls?.agree,
                  })
                }
                checked={formControls.agree}
                className="mt-[5px] block"
                type="checkbox"
              />
            </div>
            <span className="block">
              I agree to the{" "}
              <Link to={"/terms-condition"} class=" underline">
                Terms and Conditions
              </Link>
              ,{" "}
              <Link to={"/privacy"} class=" underline">
                Privacy Policy
              </Link>{" "}
              and{" "}
              <Link to={"/cookie"} class=" underline">
                Cookie Policy
              </Link>
              . I confirm I am 18 years or older and agree to receive emails
              from onehubplay.com.
            </span>
          </label>
          {/* <button onClick={() => handleSubmit()} className=" bg-[#18AD58] px-[50px] py-[15px] text-2xl rounded-full text-white font-bold uppercase block mx-auto">Continue</button> */}
          {loading ? (
            <button
              type="button"
              className=" bg-[#18AD58] px-[25px] md:px-[50px] py-[7px] md:py-[15px] text-2xl rounded-full text-white font-bold uppercase block mx-auto"
            >
              <img width={25} src={"/assets/Images/Eclipse-1s-200px.svg"}></img>
            </button>
          ) : (
            <button
              type="submit"
              className=" bg-[#18AD58] px-[25px] md:px-[50px] py-[7px] md:py-[15px] text-base md:text-2xl rounded-full text-white font-bold uppercase block mx-auto"
            >
              REGISTER NOW
            </button>
          )}

          {/* <p className='text-gray-500 text-sm w-[80%] mx-auto text-center mt-[50px]'>
                        <span className='block'>
                            By clicking the button, you agree to the <Link className='underline' to={"/terms-condition"}>
                                Terms and Conditions</Link>, <Link className='underline' to={"/privacy"}>Privacy Policy</Link> and <Link to={"/cookie"} className='underline'>Cookie Policy</Link>.
                            You confirm you are 18 years or older and agree to receive emails from onehubplay.com.
                        </span>
                    </p> */}
        </form>
      </div>
    </div>
  );
};

export default CreateAccount3;
