import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Index from "./Pages/Index";
import Home from "./Pages/Home";
import Nav from "./Layout/Nav";
import Footer from "./Layout/Footer";
import AudioBookPlayer from "./Pages/AudioBookPlayer";
import AudioBooks from "./Pages/AudioBooks";
import Cookie from "./Pages/Cookie";
import EBookReader from "./Pages/E-bookReader";
import Ebooks from "./Pages/E-books";
import FAQ from "./Pages/FAQ";
import MoviePlayer from "./Pages/MoviePlayer";
import Movies from "./Pages/Movies";
import Musics from "./Pages/Musics";
import MusicPlayer from "./Pages/MusicPlayer";
import MyAccount from "./Pages/MyAccount";
import Privacy from "./Pages/Privacy";
import TC from "./Pages/T&C";
import Register from "./Pages/Register";
import Subscribe from "./Pages/Subscribe";
import SlotSubscribe from "./Pages/SlotSubscribe";
import Subscribe39 from "./Pages/Subscribe39";
import Subscribe2 from "./Pages/Subscribe2";
import CancelSubscription from "./Pages/CancelSubscription";
import UploadItem from "./Pages/UploadItem";
import ListItems from "./Pages/ListItems";
import { Toaster } from "react-hot-toast";
import Registration from "./Pages/Registration";
import axios from "axios";
import { useAuth } from "./AuthContext";
import About from "./Pages/About";
import AccountVerified from "./Pages/AccountVerified";
import ResetPassword from "./Pages/ResetPassword";
import ForgetPassword from "./Pages/ForgetPassword";
import { initializeTagManager } from "./gtm";
import PaymentError from "./Pages/PaymentError";
import PaymentSuccessful from "./Pages/PaymentSuccessful";
import HttpsRedirect from "react-https-redirect";
import UnsubscribeNotification from "./Pages/UnsubscribeNotification";
import { useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ProtectedLayout from "./Layout/ProtectedLayout";
import PrimePlayPromo from "./Pages/PrimePlayPromo";
import PrimePlayRegister from "./Pages/PrimePlayRegister";
import GeneralLayout from "./Layout/GeneralLayout";
import Games from "./Pages/Games";
import Game from "./Pages/Game";
import GamePlaceholder from "./Pages/GamePlaceholder";
import GamePlaceholderFree from "./Pages/GamePlaceholderFree";
import Support from "./Pages/Support";
import CancelSuccess from "./Pages/CancelSuccess";
import CancelSuscriptionError from "./Pages/CancelSuscriptionError";

function App() {
  axios.defaults.withCredentials = true;
  const { state, dispatch } = useAuth();
  const [loading, setLoading] = useState(true);
  const unAuthRoute = [
    "/",
    "/lp1",
    "/PrimePlayPromo",
    "/PrimePlayRegister",
    "/register",
    "/login",
    "/subscribe",
    "/subscribe39",
    "/slot-subscribe",
    "/lp3",
    "/privacy",
    "/FAQ",
    "/Errorsus",
    "/cookie",
    "/terms-condition",
    "/about",
    "/verify",
    "/forget-password",
    "/payment-success",
    "/payment-error",
    "/reset-password",
    "/cancel-notification",
    "/support",
  ];

  useEffect(() => {
    const getUserData = async () => {
      let userData = localStorage.getItem("USER_DATA");
      let tokenData = localStorage.getItem("USER_TOKEN");
      if (
        userData != "null" &&
        tokenData != "null" &&
        userData != null &&
        tokenData != null &&
        userData != "" &&
        tokenData != "/reset-password"
      ) {
        dispatch({
          type: "RESTORE",
          payload: { user: JSON.parse(userData), token: JSON.parse(tokenData) },
        });
        setLoading(false);
      } else {
        setLoading(false);
      }
    };
    getUserData();
  }, []);

  useEffect(() => {
    initializeTagManager();
  }, []);

  return (
    <HttpsRedirect>
      <BrowserRouter>
        {!loading && (
          <>
            <Nav unAuthRoute={unAuthRoute}></Nav>
            <Routes>
              <Route element={<ProtectedLayout />}>
                <Route
                  path="/audioBookPlayer/:id"
                  element={<AudioBookPlayer />}
                />
                <Route path="/home" element={<Home />} />
                <Route path="/audioBooks" element={<AudioBooks />} />
                <Route path="/eBookReader/:id" element={<EBookReader />} />
                <Route path="/ebooks" element={<Ebooks />} />
                <Route path="/moviePlayer/:id" element={<MoviePlayer />} />
                <Route path="/movies" element={<Movies />} />
                <Route path="/musics" element={<Musics />} />
                <Route path="/games" element={<Games />} />
                <Route path="/games/:id" element={<Game />} />
                <Route
                  path="/games/:id/play"
                  element={
                    state?.isAuth ? (
                      <GamePlaceholder />
                    ) : (
                      <GamePlaceholderFree />
                    )
                  }
                />
                <Route path="/musicPlayer/:id" element={<MusicPlayer />} />
                <Route path="/myAccount" element={<MyAccount />} />
                <Route
                  path="/payment-success"
                  element={<PaymentSuccessful />}
                />  
                <Route path="/payment-error" element={<PaymentError />} />
              </Route>
              <Route path="/" index element={<Index />}></Route>
              <Route path="/cookie" element={<Cookie />} />
              <Route path="/FAQ" element={<FAQ />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms-condition" element={<TC />} />
              <Route path="/Errorsus" element={<CancelSuscriptionError />} />

              <Route path="/register" element={<Register />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/forget-password" element={<ForgetPassword />} />
              <Route path="/login" element={<Register />} />
              <Route path="/about" element={<About />} />
              <Route path="/lp1" element={<Registration />} />
              <Route path="/PrimePlayPromo" element={<PrimePlayPromo />} />
              <Route
                path="/PrimePlayRegister"
                element={<PrimePlayRegister />}
              />
              <Route path="/slot-subscribe" element={<SlotSubscribe />} />
              <Route path="/subscribe" element={<Subscribe />} />
              <Route path="/subscribe39" element={<Subscribe39 />} />
              <Route path="/lp3" element={<Subscribe2 />} />
              <Route path="/admin-uploadItem" element={<UploadItem />} />
              <Route path="/admin-listItem" element={<ListItems />} />
              <Route path="/verify" element={<AccountVerified />} />
              <Route
                path="/cancel-notification"
                element={<UnsubscribeNotification />}
              />
              <Route path="/support" element={<Support />} />

              <Route path="/cancel-success" element={<CancelSuccess />} />
            </Routes>

            <Footer></Footer>
            <Toaster
              position="top-center"
              reverseOrder={false}
              gutter={8}
              containerClassName=""
              containerStyle={{}}
              toastOptions={{
                // Define default options
                className: "",
                duration: 5000,
                style: {
                  background: "#363636",
                  color: "#fff",
                },

                // Default options for specific types
                success: {
                  duration: 3000,
                  theme: {
                    primary: "green",
                    secondary: "black",
                  },
                },
              }}
            />
          </>
        )}
      </BrowserRouter>
    </HttpsRedirect>
  );
}

export default App;
