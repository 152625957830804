import React from "react";
import { Link } from "react-router-dom";

export default function CancelSuscriptionError() {
  return (
    <div className=" pb-[100px] bg-[#fff] pt-[50px] lg:h-[80vh]">
      <img
        src="./assets/Images/credit-card.png"
        className="w-[150px] block mb-[20px] mx-auto"
      />
      <h1 className="text-gray-500 font-bold text-[40px] text-center mb-[20px]">
        CancelSuscriptionError
      </h1>
      <div className="text-center">
        <Link
          to={"/subscribe"}
          className=" bg-[#18AD58] px-[40px] py-[10px] text-[20px] rounded-xl text-white font-bold"
        >
          Try Again
        </Link>
      </div>
    </div>
  );
}
