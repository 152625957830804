import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { baseUrl } from "../Utils/app";
import ItemCard from "./Components/ItemCard";

import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css";

const Home = () => {
  let loadingToast;
  const [loading, setLoading] = useState(true);
  const [featured, setFeatured] = useState([]);
  const [items, setItems] = useState({
    movies: [],
    musics: [],
    ebooks: [],
    audioBooks: [],
  });

  useEffect(() => {
    loadingToast = toast.loading("Loading");

    const getItems = async () => {
      axios.defaults.withCredentials = true;
      return await axios.get(`${baseUrl}/api/contentItem`);
    };

    getItems()
      .then((resp) => {
        setItems(resp.data.data);
        setFeatured(resp.data.data.featured);
        toast.dismiss(loadingToast);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss(loadingToast);
        toast.error(error?.message);
      });
  }, []);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // You can use 'auto' for an instant scroll
      });
    };
    scrollToTop();
  }, []);

  return (
    <>
      {!loading ? (
        <>
          <Swiper
            modules={[Navigation, Pagination, A11y, Autoplay]}
            spaceBetween={0}
            slidesPerView={1}
            navigation
            autoplay={{
              delay: 4500,
              disableOnInteraction: false,
            }}
            loop={true}
            pagination={{ clickable: true }}
            // scrollbar={{ draggable: true }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log("slide change")}
          >
            {featured.length > 0 &&
              featured.map((item) => (
                <SwiperSlide>
                  <section
                    className="h-screen bg-[#241C24] bg-cover"
                    style={{
                      backgroundImage: `url(${item?.cover_image_large})`,
                    }}
                  >
                    <div className="custom-container flex items-center h-full">
                      <div className="w-[50%] min-w-[500px]">
                        {item?.type == "movie" && (
                          <h2 className="text-2xl tracking-[15px] text-white uppercase">
                            MOVIES
                          </h2>
                        )}
                        {item?.type == "music" && (
                          <h2 className="text-2xl tracking-[15px] text-white uppercase">
                            Music
                          </h2>
                        )}
                        {item?.type == "audioBook" && (
                          <h2 className="text-2xl tracking-[15px] text-white uppercase">
                            AUDIO BOOKS
                          </h2>
                        )}
                        {item?.type == "ebook" && (
                          <h2 className="text-2xl tracking-[15px] text-white uppercase">
                            E-BOOKS
                          </h2>
                        )}

                        <h1 className="text-[#18AD58] text-[50px] font-bold uppercase">
                          {" "}
                          <Link to={"/moviePlayer/" + item.id}>
                            {item?.title}
                          </Link>
                        </h1>
                        {item?.type == "music" && (
                          <p className="text-white text-xl mb-7">
                            {item?.author}
                          </p>
                        )}
                        {(item?.type == "movie" || item?.type == "ebook") && (
                          <p className="text-white text-xl mb-6 font-semibold">
                            {item?.rating}/10 • {item?.duration} •{" "}
                            {item?.release_date}
                          </p>
                        )}
                        {item?.type != "music" && (
                          <p className="text-white text-xl mb-7">
                            {item?.description}
                          </p>
                        )}

                        {item?.type == "movie" && (
                          <Link
                            to={"/moviePlayer/" + item.id}
                            className=" bg-[#18AD58] px-[40px] py-[10px] text-[20px] rounded-xl text-white font-bold cursor-pointer"                          >
                            WATCH NOW
                          </Link>
                        )}
                        {item?.type == "music" && (
                          <Link
                            to={"/musicPlayer/" + featured?.id}
                            className=" bg-[#18AD58] px-[40px] py-[10px] text-[20px] rounded-xl text-white font-bold cursor-pointer"
                          >
                            LISTEN NOW
                          </Link>
                        )}
                        {item?.type == "audioBook" && (
                          <Link
                            to={"/audioBookPlayer/" + featured?.id}
                            className=" bg-[#18AD58] px-[40px] py-[10px] text-[20px] rounded-xl text-white font-bold"
                          >
                            LISTEN NOW
                          </Link>
                        )}
                        {item?.type == "ebook" && (
                          <Link
                            to={"/eBookReader/" + featured?.id}
                            className=" bg-[#18AD58] px-[40px] py-[10px] text-[20px] rounded-xl text-white font-bold"
                          >
                            READ NOW
                          </Link>
                        )}
                      </div>
                    </div>
                  </section>
                </SwiperSlide>
              ))}
          </Swiper>

          <section
            className="pt-[100px] pb-[50px] bg-[#241C24] bg-cover"
            style={{ backgroundImage: `url(/assets/Images/sec2bg.png)` }}
          >
            <div className="custom-container">
              <div className=" mb-[50px]">
                <div className="flex justify-between mb-[44px]">
                  <h2 className=" text-white text-3xl font-bold ">Movies</h2>
                  <Link
                    to={"/movies"}
                    className=" block bg-[#18AD58] px-[40px] py-[5px] text-base rounded-md text-white font-semibold"
                  >
                    VIEW MORE
                  </Link>
                </div>
                <div className="w-full">
                  <Swiper
                    spaceBetween={30}
                    slidesPerView={5}
                    modules={[Navigation]}
                    navigation
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                  >
                    {items?.movies?.length > 0 &&
                      items?.movies?.map((item) => (
                        <SwiperSlide>
                          <ItemCard
                            routeName={"/moviePlayer/"}
                            item={item}
                          ></ItemCard>
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </div>
              </div>
              <div className=" ">
                <div className="flex justify-between mb-[44px]">
                  <h2 className=" text-white text-3xl font-bold ">Music</h2>
                  <Link
                    to={"/musics"}
                    className=" block bg-[#18AD58] px-[40px] py-[5px] text-base rounded-md text-white font-semibold"
                  >
                    VIEW MORE
                  </Link>
                </div>
                <div className="w-full">
                  <Swiper
                    spaceBetween={30}
                    slidesPerView={5}
                    modules={[Navigation]}
                    navigation
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                  >
                    {items?.musics?.length > 0 &&
                      items?.musics?.map((item) => (
                        <SwiperSlide>
                          <ItemCard
                            routeName={"/musicPlayer/"}
                            item={item}
                          ></ItemCard>
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </div>
              </div>
            </div>
          </section>

          <section
            className=" pb-[100px] bg-[#241C24] bg-cover mt-[-2px]"
            style={{ backgroundImage: `url(/assets/Images/sec3bg.png)` }}
          >
            <div className="custom-container">
              <div className=" mb-[50px]">
                <div className="flex justify-between mb-[44px]">
                  <h2 className=" text-white text-3xl font-bold ">
                    Audio Books
                  </h2>
                  <Link
                    to={"/audioBooks"}
                    className=" block bg-[#18AD58] px-[40px] py-[5px] text-base rounded-md text-white font-semibold"
                  >
                    VIEW MORE
                  </Link>
                </div>
                <div className="w-full">
                  <Swiper
                    spaceBetween={30}
                    slidesPerView={5}
                    modules={[Navigation]}
                    navigation
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                  >
                    {items?.audioBooks?.length > 0 &&
                      items?.audioBooks?.map((item) => (
                        <SwiperSlide>
                          <ItemCard
                            routeName={"/audioBookPlayer/"}
                            item={item}
                          ></ItemCard>
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </div>
              </div>
              <div className=" mb-[50px]">
                <div className="flex justify-between mb-[44px]">
                  <h2 className=" text-white text-3xl font-bold ">E-Books</h2>
                  <Link
                    to={"/ebooks"}
                    className=" block bg-[#18AD58] px-[40px] py-[5px] text-base rounded-md text-white font-semibold"
                  >
                    VIEW MORE
                  </Link>
                </div>
                <div className="w-full">
                  <Swiper
                    spaceBetween={30}
                    slidesPerView={5}
                    modules={[Navigation]}
                    navigation
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                  >
                    {items?.ebooks?.length > 0 &&
                      items?.ebooks?.map((item) => (
                        <SwiperSlide>
                          <ItemCard
                            routeName={"/eBookReader/"}
                            item={item}
                          ></ItemCard>
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <section
          className="h-screen bg-[#241C24] bg-cover flex items-center justify-center"
          style={{ backgroundImage: "url(./assets/Images/plainbg.png)" }}
        >
          <img src={"/assets/Images/Eclipse-1s-200px.svg"}></img>
        </section>
      )}
    </>
  );
};

export default Home;
