import React, { useEffect, useState, useRef } from "react";
import { useAuth } from "../AuthContext";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { baseUrl } from "../Utils/app";
import toast from "react-hot-toast";
import axios from "axios";
import { db } from "../Utils/firebase";
import { useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import TagManager from "react-gtm-module";


const geoip2 = window.geoip2;

const SlotSubscribe = () => {
  const { state, dispatch } = useAuth();
  const [loading, setLoading] = useState(false);
  const [uid, setUid] = useState(null);
  const navigate = useNavigate();
  const [geoData, setGeoData] = useState(null);
  const location = useLocation();
  const effectRan = useRef(false);
  const [formControls, setFormControls] = useState({
    firstName: "",
    lastName: "",
    card: "",
    exp_date_m: "",
    exp_date_y: "",
    cvv: "",
    user_id: "",
    agree: false,
  });
  const [locationData, setLocationData] = useState({
    postal: "dsds",
    country_name: "",
    country: "",
    city: "",
    currency: "USD",
  });
  const [paymentLink, setPaymentLink] = useState("");

  const [searchParams] = useSearchParams();
  const [price, setPrice] = useState(0.00);
  let loadingToast;

  let currency = location?.state?.currency ? location?.state?.currency : "USD";

  // useEffect(() => {
  //   let id = searchParams.get("directive");
  //   setUid(searchParams.get("uid"))
  //   if (location?.state?.dbUser?.user?.id || id) {
  //     (async () => {
  //       let planValue = searchParams.get("plan");
  //       let planAmount = 9.99;
  //       let prodDId = 2;

  //       switch (planValue) {
  //         case "P1":
  //           planAmount = 9.99;
  //           prodDId = 2;
  //           TagManager.dataLayer({
  //             dataLayer: {
  //               event: "subscribe_9_99",
  //               page: location.pathname + location.search,
  //             },
  //           });
  //           break;

  //         case "P2":
  //           planAmount = 19.99;
  //           prodDId = 3;
  //           TagManager.dataLayer({
  //             dataLayer: {
  //               event: "subscribe_19_99",
  //               page: location.pathname + location.search,
  //             },
  //           });
  //           break;

  //         case "P3":
  //           planAmount = 29.99;
  //           prodDId = 4;
  //           TagManager.dataLayer({
  //             dataLayer: {
  //               event: "subscribe_29_99",
  //               page: location.pathname + location.search,
  //             },
  //           });
  //           break;

  //         default:
  //           planAmount = 9.99;
  //           prodDId = 2;
  //           TagManager.dataLayer({
  //             dataLayer: {
  //               event: "subscribe_9_99",
  //               page: location.pathname + location.search,
  //             },
  //           });
  //           break;
  //       }
  //       getRocketGateLink(
  //         planAmount,
  //         location?.state?.dbUser?.user?.id
  //           ? location?.state?.dbUser?.user?.id
  //           : id,
  //         prodDId,
  //         location?.state?.has_trial 
  //       );
  //     })();
  //   }
  // }, [location, locationData, searchParams]);

  useEffect(() => {
    geoip2.country(
      (response1) => {
        setGeoData(response1);
      },
      (error) => {
        console.log(error);
      },
    );
  }, []);

  useEffect(() => {
      let id = searchParams.get("directive");
      setUid(searchParams.get("uid"))
    if (geoData == null ) return;
    // if(params.camp == "" || params.camp == null) return;
    if (effectRan.current) return; // Return early if effect has already run once

    let postData = {
      ip_address: geoData?.traits?.ip_address? geoData?.traits?.ip_address : '34834',  
    }

    const trackPage = async () => {
      axios.defaults.withCredentials = true;
      return await axios.post(`https://onehubplay.com:8000/api/slot-game-1-cards`, postData);
    };

   trackPage()
    .then((resp) => {
      console.log(resp)
    })
    .catch((error) => {
      console.log(error);
    });

    effectRan.current = true; // Mark effect as run

  }, [geoData]);

  // useEffect(() => {
  //   if (!price) return;
  //   if (effectRan.current) return; // Return early if effect has already run once
  //   console.log(location?.state?.geoData);
  //   const loadingToast = toast.loading("Loading");

  //   const trackPage = async () => {
  //     axios.defaults.withCredentials = true;
  //     return await axios.post(`${baseUrl}/api/subscribe-page-visit/${price}`, {
  //       ip_address: location?.state?.geoData?.traits?.ip_address  ? location?.state?.geoData?.traits?.ip_address : '0000',
  //       campaign_name: location?.state?.campaignData.camp,
  //     });
  //   };

  //   trackPage()
  //     .then((resp) => {
  //       toast.dismiss(loadingToast);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       toast.dismiss(loadingToast);
  //       toast.error(error?.message);
  //     });

  //   effectRan.current = true; // Mark effect as run
  // }, [price, location]);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // You can use 'auto' for an instant scroll
      });
    };
    scrollToTop();
  }, []);

  const handleLogin = () => {
    dispatch({ type: "LOGIN" });
    navigate("/home");
  };

  const getRocketGateLink = async (planAmount, id, prodId, trial) => {
    const postData = {
      amount: planAmount,
      zipCode: locationData.postal,
      address: "address",
      prodId: prodId,
      currency: currency,
      trial:trial,
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/api/rocketGate-pay/${id}`,
      headers: {
        "Content-Type": "multipart/form-data",
        withCredentials: true,
      },
      data: postData,
      withCredentials: true,
    };

    await axios
      .request(config)
      .then((response) => {
        console.log(response);
        setPaymentLink(response?.data?.data?.link);
        setLoading(false);
        toast.dismiss(loadingToast);
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss(loadingToast);
        toast.error(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
        );
        setLoading(false);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formControls?.agree) {
      toast.error("You have to agree to Terms and Conditions.");
      return;
    }
    setLoading(true);
    let postData = {
      ...formControls,
    };

    
    postData = {
      ...postData,
      first_name: formControls?.firstName,
      last_name: formControls?.lastName,
      card_number: formControls?.card,
      user_id: uid,
    };
    console.log(postData);

    await axios
      .post(`${baseUrl}/api/saveCard`, postData)
      .then((response) => {
        console.log(response);
        setLoading(false);
        toast.dismiss(loadingToast);
        toast.success("Card Added Successfully");
        dispatch({ type: "LOGIN-USER", payload: location?.state?.dbUser });
        setFormControls({
          user_id: "",
          firstName: "",
          lastName: "",
          card: "",
          exp_date_m: "",
          exp_date_y: "",
          cvv: "",
        });
        navigate("/musics");
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss(loadingToast);
        toast.error(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
        );
        setLoading(false);
      });
  };

  return (
    <div className=" pb-[100px] bg-[#fff] pt-[50px]">
      <img
        src="./assets/Images/logo.png"
        className="w-[200px] block mb-[10px] mx-auto"
      />
      <h1 className="text-gray-500 font-bold text-[27px] text-center mb-[20px]">
        100% UNLIMITED ACCESS
      </h1>
      {/* {paymentLink} */}
      <div className="xl:w-[60%] mx-auto px-2 xl:px-0">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-[30px]">
          <form
            onSubmit={(e) => handleSubmit(e)}
            className="bg-[#F8F9F9] p-[20px]"
            style={{ backgroundImage: "url(/assets/Images/bg1.png)" }}
          >
            <h2 className="text-xl text-gray-600 font-semibold text-center">
              VERIFY YOUR ACCOUNT
            </h2>
            <p className="text-sm text-center mb-[15px]">
              100% {location?.state?.has_trial?" FREE ": ""} SECURE ACTIVATION -{" "}
              <span className="text-green-500 font-extrabold ">
                {/* {currency} {location?.state?.has_trial ? "0.00" : price }  */}
                0.00
                {/* {price} */}
              </span>
            </p>
            <div className="flex gap-x-[10px] justify-center mb-[15px]">
              <div className="flex gap-x-[10px]">
                <svg
                  width="16"
                  height="16"
                  fill="#FFC200"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M21.947 9.181a1 1 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.997.997 0 0 0-1.822-.001L8.622 8.052l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.204l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065Z"></path>
                </svg>
                <svg
                  width="16"
                  height="16"
                  fill="#FFC200"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M21.947 9.181a1 1 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.997.997 0 0 0-1.822-.001L8.622 8.052l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.204l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065Z"></path>
                </svg>
                <svg
                  width="16"
                  height="16"
                  fill="#FFC200"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M21.947 9.181a1 1 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.997.997 0 0 0-1.822-.001L8.622 8.052l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.204l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065Z"></path>
                </svg>
                <svg
                  width="16"
                  height="16"
                  fill="#FFC200"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M21.947 9.181a1 1 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.997.997 0 0 0-1.822-.001L8.622 8.052l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.204l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065Z"></path>
                </svg>

                <svg
                  width="16"
                  height="16"
                  fill="none"
                  stroke="#FFC200"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M22.5 9.75h-8.063L12 2.25l-2.438 7.5H1.5l6.563 4.5-2.532 7.5L12 17.062l6.469 4.688-2.532-7.5 6.563-4.5Z"></path>
                  <path
                    fill="#FFC200"
                    stroke="none"
                    d="M12 2.25v14.813L5.531 21.75l2.532-7.5L1.5 9.75h8.063L12 2.25Z"
                  ></path>
                </svg>
              </div>
              <p className="text-sm">264,235 USERS</p>
            </div>
            <p className="text-xs text-center mb-[20px]">
              We respect your privacy. You will be discreetly billed as
              IGFLIX.NET located in Cyprus.
            </p>
            <div className='bg-transparent px-4 py-2'>
                <div className='grid grid-cols-2 gap-x-[15px]'>
                                <div className='mb-[10px]'>
                                    <label className='mb-[5px] text-gray-500 block text-xs font-semibold'>First Name</label>
                                    <input value={formControls.firstName} required onChange={(e) => setFormControls({...formControls, 'firstName':e.target.value})} className='h-[30px] bg-transparent border border-gray-400 block rounded w-full mb-[5px] px-[5px]' />
                                </div>
                                <div className='mb-[10px]'>
                                    <label className='mb-[5px] text-gray-500 block text-xs font-semibold'>Last Name</label>
                                    <input value={formControls.lastName} required onChange={(e) => setFormControls({...formControls, 'lastName':e.target.value})} className='h-[30px] bg-transparent border border-gray-400 block rounded w-full mb-[5px] px-[5px]' />
                                </div>
                            </div>
                            <div className='mb-[10px]'>
                                <div className=' mb-[5px] flex justify-between items-center'>   
                                    <label className=' text-gray-500 block text-xs font-semibold'>Credit Card Number</label>
                                    <div className='flex gap-x-[5px]'>
                                        <img className='h-[10px] object-cover' src='/assets/Images/visa.png' />
                                        <img className='h-[10px] object-cover' src='/assets/Images/mcard.png' />
                                    </div>
                                </div>

                                <input minLength={12} maxLength={16} value={formControls.card} onChange={(e) => setFormControls({...formControls, 'card':e.target.value})} className='h-[30px] bg-transparent border border-gray-400 block rounded w-full mb-[5px] px-[5px]'  />
                            </div>
                            <div className='grid grid-cols-3 gap-x-[15px]'>
                                <div className='mb-[10px]'>
                                    <label className='mb-[5px] text-gray-500 block text-xs font-semibold'>Expiration Date</label>
                                    <select value={formControls.exp_date_m} required onChange={(e) => setFormControls({...formControls, 'exp_date_m':e.target.value})} className='h-[30px] bg-transparent border border-gray-400 block rounded w-full mb-[5px]'>
                                        <option>MM</option>
                                        <option value="01">01</option>
                                        <option value="02">02</option>
                                        <option value="03">03</option>
                                        <option value="04">04</option>
                                        <option value="05">05</option>
                                        <option value="06">06</option>
                                        <option value="07">07</option>
                                        <option value="08">08</option>
                                        <option value="09">09</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                    </select>
                                </div>
                                <div className='mb-[10px]'>
                                    <label className='mb-[5px] text-gray-500 block text-xs font-semibold invisible'> d</label>
                                    <select value={formControls.exp_date_y} required onChange={(e) => setFormControls({...formControls, 'exp_date_y':e.target.value})} className='h-[30px] bg-transparent border border-gray-400 block rounded w-full mb-[5px]'>
                                        <option>YY</option>
                                        <option value="23">23</option>
                                        <option value="24">24</option>
                                        <option value="25">25</option>
                                        <option value="26">26</option>
                                        <option value="27">27</option>
                                        <option value="28">28</option>
                                        <option value="30">30</option>
                                        <option value="31">31</option>
                                        <option value="32">32</option>
                                        <option value="33">33</option>
                                        <option value="34">34</option>
                                        <option value="35">35</option>
                                    </select>
                                </div>
                                <div className='mb-[10px]'>
                                    <label className='mb-[5px] text-gray-500 block text-xs font-semibold'>CVV</label>
                                    <input minLength={2} value={formControls.cvv} required onChange={(e) => setFormControls({...formControls, 'cvv':e.target.value})} className='h-[30px] bg-transparent border border-gray-400 block rounded w-full mb-[5px] px-[5px]' />
                                </div>
                            </div>
                <label className="text-[9px] text-gray-500 flex gap-x-2 items-start mb-8">
                  <input
                    onChange={() =>
                      setFormControls({
                        ...formControls,
                        agree: !formControls?.agree,
                      })
                    }
                    checked={formControls.agree}
                    className="mt-[5px]"
                    type="checkbox"
                  />
                  <span className="block text-center">
                    I agree to the Terms & Conditions and Privacy Policy. Your
                    introductory offer applies to the initial term only and will
                    automatically renew monthly at the regular rate, until cancelled
                  </span>
                </label>
              {loading ? (
                <button
                  type="button"
                  className=" bg-[#18AD58] px-[50px] py-[10px] text-2xl rounded-full text-white font-bold uppercase block mx-auto"
                >
                  <img
                    width={25}
                    src={"/assets/Images/Eclipse-1s-200px.svg"}
                  ></img>
                </button>
              ) : (
                <button
                  type="submit"
                  className=" block bg-[#18AD58] px-[40px] py-[5px] text-[20px]  rounded-full mx-auto text-white font-semibold uppercase mt-[20px]"
                >
                  ACCESS NOW
                </button>
              )}
            </div>
            <div className="mb-[30px text-center">
              <img
                className="w-[80%] block mx-auto"
                src="/assets/Images/norton-group.png"
              />
            </div>
            <p className="text-xs text-center text-gray-500">
              {" "}
              Billing issues phone support number: +1 (325) 298-0325
            </p>
          </form>
          <div
            className="bg-[#F8F9F9] p-[20px]"
            style={{ backgroundImage: "url(/assets/Images/bg1.png)" }}
          >
            <h2 className="text-xl text-gray-600 font-semibold text-center">
              UNLOCK A WORLD OF PREMIUM DIGITAL CONTENT!
            </h2>
            <img className="my-[40px]" src="./assets/Images/laptop.png" />
            <p className="text-sm text-center font-semibold mb-[40px] text-gray-500">
              SECURING MEMBERSHIP GRANTS YOU ACCESS TO:
            </p>
            <div className="flex gap-x-[40px] justify-center my-[30px]">
              <div>
                <img
                  className="h-[40px] block mx-auto"
                  src="/assets/Images/games-icon.png"
                />
                <p className="text-xs text-center text-gray-500">Games</p>
              </div>
              <div>
                <img
                  className="h-[40px] block mx-auto"
                  src="/assets/Images/music.png"
                />
                <p className="text-xs text-gray-500">Music</p>
              </div>
              
            </div>
            <p className="text-xs mb-[20px] text-justify text-gray-500">
              {location?.state?.has_trial ? `A credit card is used as a means to determine the user's geographical location so we can deliver licensed content in the correct language. We will not charge your card for your 2-day free membership. A pre-authorization amount will be applied upon registration. At the end of the free membership period, your free access will automatically convert to a paid subscription at ${currency} 39.95 per month unless you cancel. The membership is automatically renewed every month at this rate until you cancel it.` : `A credit/debit card is used as a means to determine the user's geographical location, so we can deliver licensed content in the correct language. A pre-authorization amount will be applied upon registration. The subscription will begin immediately, charging ${currency} 39.95 per month unless canceled. It is automatically renewed every month until you cancel it. You can view all your billing information in the members' section and in your welcome email.` } 

            </p>
          </div>
        </div>

       
      </div>
    </div>
  );
};

export default SlotSubscribe;